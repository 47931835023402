:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
}

.banner {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sidenav {
  width: 250px;
  transition: width 0.3s ease-in-out;
}

.sidenavClosed {
  transition: width 0.3s ease-in-out;
  width: 60px;
}

.menuBtn {
  display: flex;
  justify-content: end;
  border: none;
  cursor: pointer;
  width: 100%;
}

/* status  */
.COMPLETED,
.APPROVED,
.PENDING,
.REJECTED,
.FULLY_RESTRICTED,
.PARTIALLY_RESTRICTED,
.SUCCESSFUL,
.ACTIVE,
.FAILED {
  border-radius: 0.5rem;
  text-align: center;
  font-size: 0.7rem;
  width: fit-content;
  padding: 0.4rem 1rem;
}

.user_header {
  display: flex;
  justify-content: space-between;
}
.user_field {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.user_field_text {
  font-size: 1.8rem;
  font-weight: 600;
}
.user__select {
  width: 10rem;
  margin-left: 2rem;
}
.user_table {
  margin-top: 3rem;
}

label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

span {
  color: #232323;
  font-size: 15px;
  line-height: 17px;
  white-space: nowrap;
}

input[type="checkbox"] {
  display: none;
}

/* input[type="checkbox"] + span:before {
  width: 15px;
  height: 15px;
  border: 1.5px solid #f0f0f0;
  content: "";
  display: inline-block;
  margin: 0 6px 0 0;
  padding: 0;
  vertical-align: top;
  border-radius: 50%;
}

input[type="checkbox"]:checked + span:before {
  background: #00a239;
  border: 1.5px solid #00a239;
  box-shadow: inset 0px 0px 0px 1px #fff;
  color: #333;
  content: "";
  text-align: center;
  border-radius: 50%;
}

input[type="checkbox"]:focus + span::before {
  outline: 0;
} */

.react-datepicker-popper {
  z-index: 99 !important;
}
